import type { NextPage } from "next";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import ContactForm from "../home/contact-form";

export type PageContentType = {
  className?: string;
};

const PageContent: NextPage<PageContentType> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <div className="self-stretch rounded-3xl bg-whitesmoke-100 flex flex-row flex-wrap items-start justify-start py-[60px] px-20 mq450:px-[12px] mq450:py-[20px] box-border gap-[40px] max-w-full text-sm mq1225:pl-10 mq1225:pr-10 mq1225:box-border mq850:gap-[20px] mq850:pt-[39px] mq850:pb-[39px] mq850:box-border">

      <div className="m-0 flex-1 flex flex-col items-end justify-start gap-[31px] min-w-[315px] max-w-full mq850:gap-[15px]  mq450:min-w-0">
        <div className="self-stretch flex flex-col items-center justify-start gap-[12px] max-w-full">
          <div className="self-stretch flex flex-row items-center justify-start gap-[8px] max-w-full">
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <img
                className="h-6 w-[28.5px] relative"
                alt=""
                src="/frame-2.svg"
              />
              <div className="w-[57px] relative text-base font-semibold font-headline text-darkslateblue text-left hidden">
                S-pack
              </div>
            </div>
            <h2 className="m-0 flex-1 relative text-5xl font-medium font-headline text-text-title text-left inline-block min-w-[274px] max-w-full mq450:text-lgi">
              {t('contact.company_name')}
            </h2>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start py-0 px-0 box-border gap-[4px] max-w-full">
            <div className="w-[545.4px] flex flex-row items-center justify-start gap-[8px] max-w-[113%] shrink-0">
              <img
                className="h-4 w-4 relative overflow-hidden shrink-0"
                alt=""
                src="/pin-drop-11.svg"
              />
              <div className="flex-1 relative text-sm font-medium font-headline text-text-content-1 text-left inline-block max-w-[calc(100%_-_24px)]">
                {t('contact.address')}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-6 mb-[8px] box-border max-w-full">
              <div className="flex-1 relative text-sm font-light font-headline text-text-content-1 text-left inline-block max-w-full">
                <p className="m-0">{t('contact.address_hanoi')}</p>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-6 box-border max-w-full">
              <div className="flex-1 relative text-sm font-light font-headline text-text-content-1 text-left inline-block max-w-full">
                <p className="m-0">{t('contact.address_hcm')}</p>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start py-0 px-0 box-border gap-[4px] max-w-full">
            <div className="w-[545.4px] flex flex-row items-center justify-start gap-[8px] max-w-[113%] shrink-0">
              <img
                className="h-4 w-4 relative overflow-hidden shrink-0"
                alt=""
                src="/mail-2.svg"
              />
              <div className="flex-1 relative text-sm font-medium font-headline text-text-content-1 text-left inline-block max-w-[calc(100%_-_24px)]">
                {t('contact.email')}
              </div>
            </div>
            <div className="w-[569.4px] flex flex-row items-start justify-start py-0 px-6 box-border max-w-[118%] shrink-0">
              <div className="flex-1 relative text-sm font-light font-headline text-text-content-1 text-left inline-block whitespace-nowrap max-w-full">
                info@spackplastic.com
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start py-0 px-0 box-border gap-[4px] max-w-full">
            <div className="w-[545.4px] flex flex-row items-center justify-start gap-[8px] max-w-[113%] shrink-0">
              <img
                className="h-4 w-4 relative overflow-hidden shrink-0"
                alt=""
                src="/phone-android-2.svg"
              />
              <div className="flex-1 relative text-sm font-medium font-headline text-text-content-1 text-left inline-block max-w-[calc(100%_-_24px)]">
                {t('contact.phone')}
              </div>
            </div>
            <div className="w-[569.4px] flex flex-row items-start justify-start py-0 px-6 box-border max-w-[118%] shrink-0">
              <div className="flex-1 relative text-sm font-light font-headline text-text-content-1 text-left inline-block max-w-full">
                <p className="m-0">{t('contact.phone_number.hanoi')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[12px] max-w-ful">
          <h2 className="m-0 self-stretch relative text-5xl font-medium font-headline text-primary text-left mq450:text-lgi">
            {t('common.contact_us')}
          </h2>
          <div className="flex-1 max-w-ful relative text-sm font-light font-headline text-text-content-1 text-left inline-block max-w-full">
            <p className="m-0">{t('contact.intro_contact')}</p>
          </div>
        </div>
        <ContactForm/>
        {/* <div className="self-stretch flex flex-col items-start justify-start gap-[20px] max-w-full">
          <TextField
            className="[border:none] bg-[transparent] self-stretch h-9 font-headline font-light text-sm text-darkgray"
            placeholder={t('contact.input_company')}
            variant="outlined"
            sx={{
              "& fieldset": { borderColor: "#9e9e9e" },
              "& .MuiInputBase-root": {
                height: "36px",
                backgroundColor: "#fff",
                fontSize: "14px",
              },
              "& .MuiInputBase-input": { color: "#9e9e9e" },
            }}
          />
          <div className="flex-1 relative text-sm font-medium font-headline text-text-content-1 text-left inline-block max-w-[calc(100%_-_24px)]">
            {t('contact.contact_person')}
          </div>
          <TextField
            className="[border:none] bg-[transparent] self-stretch h-9 font-headline font-light text-sm text-darkgray"
            placeholder={t('contact.input_phone')}
            variant="outlined"
            sx={{
              "& fieldset": { borderColor: "#9e9e9e" },
              "& .MuiInputBase-root": {
                height: "36px",
                backgroundColor: "#fff",
                fontSize: "14px",
              },
              "& .MuiInputBase-input": { color: "#9e9e9e" },
            }}
          />
          <TextField
            className="[border:none] bg-[transparent] self-stretch h-9 font-headline font-light text-sm text-darkgray"
            placeholder={t('contact.input_email')}
            variant="outlined"
            sx={{
              "& fieldset": { borderColor: "#9e9e9e" },
              "& .MuiInputBase-root": {
                height: "36px",
                backgroundColor: "#fff",
                fontSize: "14px",
              },
              "& .MuiInputBase-input": { color: "#9e9e9e" },
            }}
          />
          <TextField
            className="[border:none] bg-[transparent] self-stretch h-9 font-headline font-light text-sm text-darkgray"
            placeholder={t('contact.input_location')}
            variant="outlined"
            sx={{
              "& fieldset": { borderColor: "#9e9e9e" },
              "& .MuiInputBase-root": {
                height: "36px",
                backgroundColor: "#fff",
                fontSize: "14px",
              },
              "& .MuiInputBase-input": { color: "#9e9e9e" },
            }}
          />
          <TextField
            className="[border:none] bg-[transparent] self-stretch font-headline font-light text-sm text-darkgray"
            placeholder={t('contact.input_message')}
            variant="outlined"
            multiline // Thêm thuộc tính này để cho phép nhập nhiều dòng
            minRows={4} // Thiết lập số hàng tối thiểu (có thể điều chỉnh)
            sx={{
              "& fieldset": { borderColor: "#9e9e9e" },
              "& .MuiInputBase-root": {
                backgroundColor: "#fff",
                fontSize: "14px",
              },
              "& .MuiInputBase-input": { color: "#9e9e9e" },
            }}
          />
          <div className="self-stretch flex flex-row justify-end max-w-full items-center">
          <Button
            className="w-[148px] h-10"
            disableElevation
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "16",
              background: "#2a77ee",
              borderRadius: "32px",
              "&:hover": { background: "#2a77ee" },
              width: 148,
              height: 40,
            }}
          >
            {t('contact.send_contact')}
          </Button>
          </div>

        </div> */}


      </div>
      <img
        className="h-[551px] flex-1 relative rounded-3xl max-w-full overflow-hidden object-cover min-w-[315px] mq850:hidden"
        loading="lazy"
        alt=""
        src="/banner3.jpg"
      />
    </div>
  );
};

export default PageContent;
