import { toast } from "react-toastify";

export const errorHandler = async (
  error: { response: { data: { message: any } }; message: any },
  autoClose = 2000,
  position = "top-left",
  closeOnClick = true,
  pauseOnHover = true
) => {
  const message =
    (await error?.response?.data?.message) ||
    error?.message ||
    error ||
    "Error";
  toast.error(message, {
    position,
    autoClose,
    closeOnClick,
    pauseOnHover,
  });
};
export const successHandler = async (
  message = "Success",
  autoClose = 2000,
  position = "top-left",
  closeOnClick = true,
  pauseOnHover = true
) => {
  toast.success(message, {
    position,
    autoClose,
    closeOnClick,
    pauseOnHover,
  });
};
