import { NextPage } from "next";
import { TextField, Button, Alert } from "@mui/material";
import { memo, useState } from "react";
import { create_contact } from "../../service/api"; // Giả định bạn đã định nghĩa hàm này
import { useTranslation } from "next-i18next";
import { errorHandler, successHandler } from "../../utils/Toast";
import { toast } from "react-toastify";

const ContactForm: NextPage = () => {
  const { t } = useTranslation()

  const [formValues, setFormValues] = useState({
    company: "",
    contactPerson: "",
    phone: "",
    email: "",
    location: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    company: false,
    contactPerson: false,
    phone: false,
    email: false,
    location: false,
    message: false,
    emailFormat: false,
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: false, emailFormat: false });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    try {
      const { company, contactPerson, phone, email, location, message } = formValues;

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const newErrors = {
        company: company === "",
        contactPerson: contactPerson === "",
        phone: phone === "",
        email: email === "",
        location: location === "",
        message: message === "",
        emailFormat: !emailRegex.test(email),
      };

      setErrors(newErrors);

      const hasErrors = Object.values(newErrors).some((error) => error);
      if (!hasErrors) {
        const res = await create_contact({ company, contactPerson, phone, email, location, message });
        if (res.status < 300 && res.status >= 200) {
          successHandler(res.data.value)
          setFormValues({ company: "", contactPerson: "", phone: "", email: "", location: "", message: "" });
        }
      }
    } catch (error) {
      toast.error(error + "")
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="self-stretch flex flex-col items-start justify-start gap-[24px] max-w-full">
      <TextField
        className="[border:none] bg-[transparent] self-stretch h-9 font-headline font-light text-sm text-darkgray"
        inputProps={{ style: { color: "black" } }}
        sx={{
          "& fieldset": { borderColor: "#9e9e9e" },
          "& .MuiInputBase-root": {
            height: "36px",
            backgroundColor: "#fff",
            fontSize: "14px",
          },
          "& .MuiInputBase-input": { color: "#9e9e9e" },
        }}
        variant="outlined"
        placeholder={t('contact.input_company')}
        name="company"
        value={formValues.company}
        onChange={handleInputChange}
        error={errors.company}
        helperText={errors.company && t('error.company')}
      />
      <TextField
        className="[border:none] bg-[transparent] self-stretch h-9 font-headline font-light text-sm"
        inputProps={{ style: { color: "black" } }}
        sx={{
          input: { color: 'red' },
          "& fieldset": { borderColor: "#9e9e9e" },
          "& .MuiInputBase-root": {
            height: "36px",
            backgroundColor: "#fff",
            fontSize: "14px",
          },
          "& .MuiInputBase-input": { color: "#9e9e9e" },
        }}
        name="contactPerson"
        placeholder={t('contact.contact_person')}
        value={formValues.contactPerson}
        onChange={handleInputChange}
        error={errors.contactPerson}
        helperText={errors.contactPerson && t('error.contact_person')}
        variant="outlined"
      />
      <TextField
        className="[border:none] bg-[transparent] self-stretch h-9 font-headline font-light text-sm text-darkgray"
        inputProps={{ style: { color: "black" } }}
        sx={{
          "& fieldset": { borderColor: "#9e9e9e" },
          "& .MuiInputBase-root": {
            height: "36px",
            backgroundColor: "#fff",
            fontSize: "14px",
          },
          "& .MuiInputBase-input": { color: "#9e9e9e" },
        }}
        name="phone"
        placeholder={t('contact.input_phone')}
        value={formValues.phone}
        onChange={handleInputChange}
        error={errors.phone}
        helperText={errors.phone &&  t('error.phone')}
        variant="outlined"
      />
      <TextField
        className="[border:none] bg-[transparent] self-stretch h-9 font-headline font-light text-sm text-darkgray"
        inputProps={{ style: { color: "black" } }}
        sx={{
          "& fieldset": { borderColor: "#9e9e9e" },
          "& .MuiInputBase-root": {
            height: "36px",
            backgroundColor: "#fff",
            fontSize: "14px",
          },
          "& .MuiInputBase-input": { color: "#9e9e9e" },
        }}
        name="email"
        placeholder={t('contact.input_email')}
        value={formValues.email}
        onChange={handleInputChange}
        error={errors.email || errors.emailFormat}
        helperText={
          errors.email ? t('error.email') : errors.emailFormat && t('error.email_invalid')
        }
        variant="outlined"
      />
      <TextField
        className="[border:none] bg-[transparent] self-stretch h-9 font-headline font-light text-sm text-darkgray"
        inputProps={{ style: { color: "black" } }}
        sx={{
          "& fieldset": { borderColor: "#9e9e9e" },
          "& .MuiInputBase-root": {
            height: "36px",
            backgroundColor: "#fff",
            fontSize: "14px",
          },
          "& .MuiInputBase-input": { color: "#9e9e9e" },
        }}
        name="location"
        placeholder={t('contact.input_location')}
        value={formValues.location}
        onChange={handleInputChange}
        error={errors.location}
        helperText={errors.location && t('error.address')}
        variant="outlined"
      />
      <TextField
        className="[border:none] bg-[transparent] self-stretch font-headline font-light text-sm text-darkgray"
        inputProps={{ style: { color: "black" } }}
        placeholder={t('contact.input_message')}
        variant="outlined"
        multiline // Thêm thuộc tính này để cho phép nhập nhiều dòng
        minRows={4} // Thiết lập số hàng tối thiểu (có thể điều chỉnh)
        sx={{
          "& fieldset": { borderColor: "#9e9e9e" },
          "& .MuiInputBase-root": {
            backgroundColor: "#fff",
            fontSize: "14px",
          },
          "& .MuiInputBase-input": { color: "#9e9e9e" },
        }}
        name="message"
        value={formValues.message}
        onChange={handleInputChange}
        error={errors.message}
        helperText={errors.message && t('error.message')}
      />
      <div className="self-stretch flex flex-row justify-end max-w-full items-center">
        <Button
          type="submit"
          className="w-[148px] h-10"
          disableElevation
          variant="contained"
          disabled={loading}
        >
          {loading ? "Đang gửi..." : t('contact.send_contact')}
        </Button>
      </div>
      {/* {success && <Alert severity="success">Thông tin đã được gửi thành công!</Alert>} */}
    </form>
  );
};

export default memo(ContactForm);